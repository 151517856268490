import t from 'counterpart';
import nb from '../texts/nb';
import en from '../texts/en';

// TODO: Header språk greie :)
const locale = 'nb';

t.setLocale(locale);
t.registerTranslations('nb', nb);
t.registerTranslations('en', en);

export default t;

// Return localized field from Sanity
export const _t = field => {
  if (field.en === null || field.nb === null) return;
  // if (field.nb === null) {
  //   field.nb = 'Mangler oversettelse';
  // }
  // if (field.en === null) {
  //   field.en = 'Missing translation';
  // }
  const localizedField = field[t.getLocale()];
  return localizedField || field;
};
