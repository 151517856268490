import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import t, { _t } from '../../utils/translate';
import styles from './CookieWarning.module.scss';
import Vector from '../Vector/Vector';
import { serializers } from '../LinkAnnotation/LinkAnnotation';

const CookieWarning = ({ closeCookieWarning }) => {
  const handleClick = () => {
    localStorage.setItem('seen', true);
    closeCookieWarning();
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          sanityPrivacy {
            popUp: _rawPrivacyPopup(resolveReferences: { maxDepth: 10 })
          }
        }
      `}
      render={({ sanityPrivacy: { popUp } }) => (
        <div className={styles.outer}>
          <div className={styles.inner}>
            <BlockContent blocks={_t(popUp)} serializers={serializers} />
            <button onClick={handleClick} className={styles.button}>
              <p className="screen-reader-only">{t('menuHide')}</p>
              <Vector type="close" colour="#fff" className={styles.close} />
            </button>
          </div>
        </div>
      )}
    />
  );
};

export default CookieWarning;
