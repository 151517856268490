import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './Header.module.scss';
import t from '../../utils/translate';
import Nav from '../Nav/Nav';
import LangSwitch from '../LangSwitch/LangSwitch';
import Logo from '../Logo/Logo';
import MobileNav from '../Nav/MobileNav/MobileNav';
import Vector from '../Vector/Vector';

export default function Header({ location }) {
  const [width, setWidth] = useState();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', setWindowSize);
    return () => {
      document.body.style.overflow = null;
      window.removeEventListener('resize', setWindowSize);
    };
  }, []);

  const setWindowSize = () => {
    setWidth(window.innerWidth);
  };

  const toggleNav = () => {
    setShowMenu(!showMenu);
    document.body.style.overflow = !showMenu ? 'hidden' : null;
  };

  return (
    <div className={styles.headerOuter}>
      <div className={styles.headerInner}>
        <Logo />
        {width < 910 ? (
          <>
            <button className={classNames(styles.button)} onClick={toggleNav}>
              <span>{showMenu ? t('menuHide') : t('menuShow')}</span>
              {showMenu ? (
                <Vector type="close" colour="#fff" />
              ) : (
                <Vector type="menu" colour="#fff" />
              )}
            </button>
          </>
        ) : (
          <div className={styles.navOuter}>
            <Nav />
            <LangSwitch />
          </div>
        )}
      </div>
      <MobileNav show={showMenu} location={location} />
    </div>
  );
}
