import React from 'react';
import { shape, string, node, oneOfType, arrayOf, oneOf } from 'prop-types';

import LocaleLink from '../LocaleLink/LocaleLink';

const isDev = process.env.NODE_ENV !== 'production';

const LINK_HREF = 'LinkHref';
const LINK_HREF_CC = 'linkHref';
const ARTICLE = 'Article';
const ARTICLE_CC = 'article';
const BESOK = 'Besok';
const BESOK_CC = 'besok';
const OPPLEVELSER = 'Opplevelser';
const OPPLEVELSER_CC = 'opplevelser';
const ABOUT = 'About';
const ABOUT_CC = 'about';
const LEARNING = 'undervisningsopplegg';
const LEARNING_CC = 'Undervisningsopplegg';
const EVENTS = 'subpage-events';
const PRIVACY = 'Privacy';
const PRIVACY_CC = 'privacy';
const FRONTPAGE = 'Frontpage';
const FRONTPAGE_CC = 'frontpage';

const LinkComponent = ({ to, children, ...anchorProps }) => {
  if (!to) {
    if (isDev) {
      throw new Error(`href, to or type required for Link component`);
    } else {
      console.error(`Invalid link`, {
        to,
        children,
        anchorProps,
      });
      return (
        // eslint-disable-next-line
        <a href="#" className="invalid">
          {children}
        </a>
      );
    }
  }

  const data = Array.isArray(to) ? to[0] : to;
  const { __typename, _type } = data;

  const type = __typename || _type;

  const isExternal = type === LINK_HREF || type === LINK_HREF_CC;

  if (isExternal) {
    return (
      <a
        href={data.href && data.href.trim()}
        rel="noopener noreferrer"
        target="_blank"
        {...anchorProps}
      >
        {children}
      </a>
    );
  }

  let url;

  switch (type) {
    case ARTICLE:
    case ARTICLE_CC: {
      const slug = data.slug.current;

      url = `/artikkel/${slug}`;
      break;
    }

    case BESOK:
    case BESOK_CC: {
      const slug = data.slug.current;

      url = `/besok/${slug}`;
      break;
    }

    case LEARNING:
    case LEARNING_CC: {
      const slug = data.slug.current;

      url = `/besok/${slug}`;
      break;
    }

    case OPPLEVELSER:
    case OPPLEVELSER_CC: {
      const slug = data.slug.current;

      url = `/opplevelser/${slug}`;
      break;
    }

    case ABOUT:
    case ABOUT_CC: {
      url = `/om-oss`;
      break;
    }

    case EVENTS: {
      const slug = data.slug.current;

      url = `/opplevelser/${slug}`;
      break;
    }

    case PRIVACY_CC:
    case PRIVACY: {
      // const slug = data.slug.current;

      url = `/personvern`;
      break;
    }

    case FRONTPAGE:
    case FRONTPAGE_CC:
      url = `/`;
      break;

    default:
      break;
  }

  return (
    <LocaleLink to={url} others={anchorProps}>
      {children}
    </LocaleLink>
  );
};

const toPropType = oneOfType([
  shape({
    _type: oneOf([LINK_HREF_CC]).isRequired,
    href: string.isRequired,
  }),
  shape({
    __typename: oneOf([LINK_HREF]).isRequired,
    href: string.isRequired,
  }),
  shape({
    _type: oneOf([ARTICLE_CC, FRONTPAGE_CC]).isRequired,
    slug: shape({
      current: string.isRequired,
    }).isRequired,
  }),
  shape({
    __typename: oneOf([ARTICLE, FRONTPAGE]).isRequired,
    slug: shape({
      current: string.isRequired,
    }).isRequired,
  }),
]);

LinkComponent.propTypes = {
  to: oneOfType([toPropType, arrayOf(toPropType)]).isRequired,
  children: node,
};

export default LinkComponent;
