import React from 'react';

const Vector = ({ type, className, colour }) => {
  const vector = type => {
    switch (type) {
      case 'arrow-up':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.527"
            height="23.826"
            viewBox="0 0 17.527 23.826"
            stroke={colour}
            strokeWidth="2px"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g transform="translate(500.85 2080.594) rotate(180)">
              <path
                d="M484.737,2093.21l7.35,7.1,7.35-7.1"
                transform="translate(0 -20.717)"
              />
              <line y2="21.826" transform="translate(492.087 2057.768)" />
            </g>
          </svg>
        );
      case 'arrow-right':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23.826"
            height="17.527"
            viewBox="0 0 23.826 17.527"
            stroke={colour}
            strokeWidth="2px"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g transform="translate(-2056.768 500.85) rotate(-90)">
              <path
                d="M484.737,2093.21l7.35,7.1,7.35-7.1"
                transform="translate(0 -20.717)"
              />
              <line y2="21.826" transform="translate(492.087 2057.768)" />
            </g>
          </svg>
        );
      case 'arrow-down':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.527"
            height="23.826"
            viewBox="0 0 17.527 23.826"
            stroke={colour}
            strokeWidth="2px"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g transform="translate(-483.323 -2056.768)">
              <path
                d="M484.737,2093.21l7.35,7.1,7.35-7.1"
                transform="translate(0 -20.717)"
              />
              <line y2="21.826" transform="translate(492.087 2057.768)" />
            </g>
          </svg>
        );
      case 'arrow-left':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23.826"
            height="17.527"
            viewBox="0 0 23.826 17.527"
            stroke={colour}
            strokeWidth="2px"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g transform="translate(2080.594 -483.323) rotate(90)">
              <path
                d="M484.737,2093.21l7.35,7.1,7.35-7.1"
                transform="translate(0 -20.717)"
              />
              <line y2="21.826" transform="translate(492.087 2057.768)" />
            </g>
          </svg>
        );

      case 'external':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.326"
            height="22.326"
            viewBox="0 0 22.326 22.326"
            fill="none"
            stroke={colour}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2px"
          >
            <g transform="translate(-221 -1285.674)">
              <path
                d="M264.737,1294.737V1287H257"
                transform="translate(-22.737)"
              />
              <line
                y1="11.747"
                x2="11.747"
                transform="translate(230.165 1287.088)"
              />
              <path
                d="M226.105,1291H221v17h17v-5.474"
                transform="translate(1 -1)"
              />
            </g>
          </svg>
        );

      case 'mail':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23.826"
            height="15.058"
            viewBox="0 0 23.826 15.058"
            fill="none"
            stroke={colour}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2px"
          >
            <g transform="translate(-90 -280.958)">
              <path
                d="M112.826,284.1v9.768a1.145,1.145,0,0,1-1.145,1.145H92.145A1.145,1.145,0,0,1,91,293.871V283.136a1.145,1.145,0,0,1,1.145-1.145h19.537a1.145,1.145,0,0,1,1.145,1.145v.968"
                transform="translate(0 0)"
              />
              <path
                d="M93,282.991l9.588,6.288a1.144,1.144,0,0,0,1.241,0l9.588-6.288"
                transform="translate(-1.296 -0.648)"
              />
            </g>
          </svg>
        );

      case 'map':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.695"
            height="19.011"
            viewBox="0 0 21.695 19.011"
            fill="none"
            stroke={colour}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2px"
          >
            <g transform="translate(-859 -901.943)">
              <path d="M861.411,919.488l4.88-1.859a1.04,1.04,0,0,1,.757.006l5.6,2.24a1.041,1.041,0,0,0,.757.006l5.621-2.141a1.04,1.04,0,0,0,.67-.972V904.382a1.041,1.041,0,0,0-1.411-.972l-4.88,1.859a1.041,1.041,0,0,1-.757-.006l-5.6-2.24a1.039,1.039,0,0,0-.757-.006l-5.621,2.141a1.04,1.04,0,0,0-.67.972v12.385A1.041,1.041,0,0,0,861.411,919.488Z" />
              <line y2="14.361" transform="translate(866.671 903.124)" />
              <line y2="14.308" transform="translate(873.024 905.413)" />
            </g>
          </svg>
        );

      case 'menu':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="18"
            viewBox="0 0 21 18"
            fill="none"
            stroke={colour}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2px"
          >
            <g transform="translate(-602.042 -2312)">
              <line x2="19" transform="translate(603.042 2313)" />
              <line x2="19" transform="translate(603.042 2329)" />
              <line x2="19" transform="translate(603.042 2321)" />
            </g>
          </svg>
        );

      case 'close':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.828"
            height="14.828"
            viewBox="0 0 14.828 14.828"
            stroke={colour}
            fill="none"
            strokeLinecap="round"
            strokeWidth="2"
          >
            <g transform="translate(-574.086 -268.086)">
              <line x2="12" y2="12" transform="translate(575.5 269.5)" />
              <line x1="12" y2="12" transform="translate(575.5 269.5)" />
            </g>
          </svg>
        );

      case 'ntnu-alt':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="179.943"
            height="61.305"
            viewBox="0 0 179.943 61.305"
            fill={colour ? colour : '#231f20'}
          >
            <g transform="translate(0 -0.001)">
              <path
                d="M-16.035,0h1.457l-4.2,11.638h-1.337L-24.282,0h1.433l2.674,7.53a21.047,21.047,0,0,1,.732,2.524,21.129,21.129,0,0,1,.749-2.571Z"
                transform="translate(65.668 45.752)"
              />
              <path
                d="M155.653-36.27h-1.321v-8.725h1.321ZM154.22-47.358a.881.881,0,0,1,.223-.665.779.779,0,0,1,.558-.211.777.777,0,0,1,.549.215.861.861,0,0,1,.231.661.873.873,0,0,1-.231.664.77.77,0,0,1-.549.219.764.764,0,0,1-.558-.219A.9.9,0,0,1,154.22-47.358Z"
                transform="translate(-101.841 93.659)"
              />
              <path
                d="M-7.724-19.083a4.294,4.294,0,0,0,.677-.052,4.544,4.544,0,0,0,.518-.107v1.011a2.451,2.451,0,0,1-.633.171,4.777,4.777,0,0,1-.752.067q-2.532,0-2.532-2.666v-5.19H-11.7v-.636l1.25-.55.557-1.863h.764v2.022h2.532v1.027H-9.125v5.135a1.767,1.767,0,0,0,.374,1.21A1.3,1.3,0,0,0-7.724-19.083Z"
                transform="translate(67.155 75.541)"
              />
              <path
                d="M-7.413-2.151a2.158,2.158,0,0,0-1.676.685A3.159,3.159,0,0,0-9.825.428h4.561a2.9,2.9,0,0,0-.557-1.914,1.96,1.96,0,0,0-1.592-.665m.358,7.937a4.01,4.01,0,0,1-3.053-1.179,4.562,4.562,0,0,1-1.118-3.271,5.058,5.058,0,0,1,1.039-3.352A3.451,3.451,0,0,1-7.4-3.258,3.29,3.29,0,0,1-4.8-2.179,4.136,4.136,0,0,1-3.847.666V1.5h-6.01a3.459,3.459,0,0,0,.776,2.333,2.685,2.685,0,0,0,2.074.8,7.027,7.027,0,0,0,2.786-.589V5.22a7.032,7.032,0,0,1-1.325.433A7.438,7.438,0,0,1-7.055,5.785Z"
                transform="translate(73.11 51.764)"
              />
              <path
                d="M-11.608-17.272v-5.644a2.274,2.274,0,0,0-.486-1.592,1.976,1.976,0,0,0-1.52-.526,2.506,2.506,0,0,0-2.006.74,3.739,3.739,0,0,0-.637,2.444v4.577h-1.321V-26H-16.5l.215,1.194h.064a2.7,2.7,0,0,1,1.138-1,3.7,3.7,0,0,1,1.632-.354,3.313,3.313,0,0,1,2.372.76,3.233,3.233,0,0,1,.8,2.432v5.692Z"
                transform="translate(89.094 74.662)"
              />
              <path
                d="M-12.025-12.645a2.186,2.186,0,0,1-.908,1.879,4.269,4.269,0,0,1-2.547.66,5.539,5.539,0,0,1-2.706-.549V-11.88a6.533,6.533,0,0,0,1.349.5,5.626,5.626,0,0,0,1.389.183,3.144,3.144,0,0,0,1.592-.331,1.092,1.092,0,0,0,.557-1.007,1.093,1.093,0,0,0-.442-.872,6.941,6.941,0,0,0-1.723-.856,9.322,9.322,0,0,1-1.731-.792,2.363,2.363,0,0,1-.764-.768,2,2,0,0,1-.251-1.026,1.957,1.957,0,0,1,.867-1.684,4.044,4.044,0,0,1,2.38-.617,6.955,6.955,0,0,1,2.754.573l-.469,1.075a6.312,6.312,0,0,0-2.38-.541,2.74,2.74,0,0,0-1.417.294.911.911,0,0,0-.478.812.99.99,0,0,0,.179.6,1.775,1.775,0,0,0,.577.469,12.906,12.906,0,0,0,1.528.645,5.7,5.7,0,0,1,2.1,1.138A2.007,2.007,0,0,1-12.025-12.645Z"
                transform="translate(99.175 67.654)"
              />
              <path
                d="M-2.538-15.4A15.514,15.514,0,0,1-1.5-16.673l2.818-2.985H2.89L-.644-15.941l3.781,5.007h-1.6l-3.081-4.123-1,.86v3.263H-3.844V-23.32h1.306v6.567q0,.438-.064,1.353Z"
                transform="translate(93.143 68.323)"
              />
              <path
                d="M-5.9-15.431a2.684,2.684,0,0,0,1.939-.677A2.5,2.5,0,0,0-3.254-18v-.788l-1.322.056a4.8,4.8,0,0,0-2.272.489,1.476,1.476,0,0,0-.7,1.35,1.36,1.36,0,0,0,.434,1.09,1.8,1.8,0,0,0,1.214.374m2.985.931-.263-1.242h-.064a3.669,3.669,0,0,1-1.3,1.111,3.971,3.971,0,0,1-1.62.29,2.915,2.915,0,0,1-2.034-.668,2.438,2.438,0,0,1-.736-1.9q0-2.642,4.227-2.77l1.481-.048v-.541a2.209,2.209,0,0,0-.442-1.516,1.815,1.815,0,0,0-1.413-.49,5.761,5.761,0,0,0-2.468.668l-.406-1.011a6.212,6.212,0,0,1,1.413-.55,6.126,6.126,0,0,1,1.54-.2,3.325,3.325,0,0,1,2.313.693,2.891,2.891,0,0,1,.752,2.221V-14.5Z"
                transform="translate(106.133 71.889)"
              />
              <path
                d="M-7.522-2.182a2.342,2.342,0,0,0-1.935.74A3.811,3.811,0,0,0-10.069.915v.294A4.368,4.368,0,0,0-9.456,3.84a2.331,2.331,0,0,0,1.967.792,2.045,2.045,0,0,0,1.771-.915,4.342,4.342,0,0,0,.641-2.524,4.179,4.179,0,0,0-.641-2.5,2.108,2.108,0,0,0-1.8-.872m.191,7.92a3.772,3.772,0,0,1-1.556-.315,2.924,2.924,0,0,1-1.182-.967h-.1a11.672,11.672,0,0,1,.1,1.449V9.5H-11.39V-3.145h1.075l.183,1.194h.064A3.074,3.074,0,0,1-8.883-2.986,3.611,3.611,0,0,1-7.331-3.3,3.226,3.226,0,0,1-4.652-2.118a5.225,5.225,0,0,1,.943,3.327,5.188,5.188,0,0,1-.959,3.339A3.23,3.23,0,0,1-7.331,5.739Z"
                transform="translate(118.313 51.81)"
              />
              <path
                d="M-12.025-12.645a2.186,2.186,0,0,1-.908,1.879,4.269,4.269,0,0,1-2.547.66,5.539,5.539,0,0,1-2.706-.549V-11.88a6.533,6.533,0,0,0,1.349.5,5.626,5.626,0,0,0,1.389.183,3.144,3.144,0,0,0,1.592-.331,1.092,1.092,0,0,0,.557-1.007,1.093,1.093,0,0,0-.442-.872,6.941,6.941,0,0,0-1.723-.856,9.322,9.322,0,0,1-1.731-.792,2.363,2.363,0,0,1-.764-.768,2,2,0,0,1-.251-1.026,1.957,1.957,0,0,1,.867-1.684,4.044,4.044,0,0,1,2.38-.617,6.955,6.955,0,0,1,2.754.573l-.469,1.075a6.312,6.312,0,0,0-2.38-.541,2.74,2.74,0,0,0-1.417.294.911.911,0,0,0-.478.812.99.99,0,0,0,.179.6,1.775,1.775,0,0,0,.577.469,12.906,12.906,0,0,0,1.528.645,5.7,5.7,0,0,1,2.1,1.138A2.007,2.007,0,0,1-12.025-12.645Z"
                transform="translate(134.566 67.654)"
              />
              <path
                d="M-21.622-17.272v-5.675a2.358,2.358,0,0,0-.446-1.564,1.724,1.724,0,0,0-1.385-.522,2.237,2.237,0,0,0-1.823.708,3.368,3.368,0,0,0-.589,2.181v4.872h-1.321v-5.675a2.354,2.354,0,0,0-.446-1.564,1.733,1.733,0,0,0-1.393-.522,2.157,2.157,0,0,0-1.819.744,4.025,4.025,0,0,0-.577,2.44v4.577h-1.321V-26h1.075l.215,1.194h.064a2.6,2.6,0,0,1,1.055-1,3.227,3.227,0,0,1,1.524-.358,2.591,2.591,0,0,1,2.674,1.481h.064a2.782,2.782,0,0,1,1.13-1.083,3.506,3.506,0,0,1,1.688-.4,2.953,2.953,0,0,1,2.216.76,3.4,3.4,0,0,1,.736,2.432v5.692Z"
                transform="translate(157.432 74.662)"
              />
              <path
                d="M-2.6,0V5.66a2.276,2.276,0,0,0,.486,1.592,1.978,1.978,0,0,0,1.52.525,2.481,2.481,0,0,0,2-.748A3.777,3.777,0,0,0,2.04,4.585V0H3.361V8.725H2.271L2.08,7.554H2.008a2.631,2.631,0,0,1-1.126.987,3.786,3.786,0,0,1-1.644.342,3.337,3.337,0,0,1-2.384-.756,3.221,3.221,0,0,1-.792-2.42V0Z"
                transform="translate(143.697 48.665)"
              />
              <path
                d="M-12.025-12.645a2.186,2.186,0,0,1-.908,1.879,4.269,4.269,0,0,1-2.547.66,5.539,5.539,0,0,1-2.706-.549V-11.88a6.533,6.533,0,0,0,1.349.5,5.626,5.626,0,0,0,1.389.183,3.144,3.144,0,0,0,1.592-.331,1.092,1.092,0,0,0,.557-1.007,1.093,1.093,0,0,0-.442-.872,6.941,6.941,0,0,0-1.723-.856,9.322,9.322,0,0,1-1.731-.792,2.363,2.363,0,0,1-.764-.768,2,2,0,0,1-.251-1.026,1.957,1.957,0,0,1,.867-1.684,4.044,4.044,0,0,1,2.38-.617,6.955,6.955,0,0,1,2.754.573l-.469,1.075a6.312,6.312,0,0,0-2.38-.541,2.74,2.74,0,0,0-1.417.294.911.911,0,0,0-.478.812.99.99,0,0,0,.179.6,1.775,1.775,0,0,0,.577.469,12.906,12.906,0,0,0,1.528.645,5.7,5.7,0,0,1,2.1,1.138A2.007,2.007,0,0,1-12.025-12.645Z"
                transform="translate(167.514 67.654)"
              />
              <path
                d="M-7.413-2.151a2.158,2.158,0,0,0-1.676.685A3.159,3.159,0,0,0-9.825.428h4.561a2.9,2.9,0,0,0-.557-1.914,1.96,1.96,0,0,0-1.592-.665m.358,7.937a4.01,4.01,0,0,1-3.053-1.179,4.562,4.562,0,0,1-1.118-3.271,5.058,5.058,0,0,1,1.039-3.352A3.451,3.451,0,0,1-7.4-3.258,3.29,3.29,0,0,1-4.8-2.179,4.136,4.136,0,0,1-3.847.666V1.5h-6.01a3.459,3.459,0,0,0,.776,2.333,2.685,2.685,0,0,0,2.074.8,7.027,7.027,0,0,0,2.786-.589V5.22a7.032,7.032,0,0,1-1.325.433A7.438,7.438,0,0,1-7.055,5.785Z"
                transform="translate(168.378 51.764)"
              />
              <path
                d="M-7.413-2.151a2.158,2.158,0,0,0-1.676.685A3.159,3.159,0,0,0-9.825.428h4.561a2.9,2.9,0,0,0-.557-1.914,1.96,1.96,0,0,0-1.592-.665m.358,7.937a4.01,4.01,0,0,1-3.053-1.179,4.562,4.562,0,0,1-1.118-3.271,5.058,5.058,0,0,1,1.039-3.352A3.451,3.451,0,0,1-7.4-3.258,3.29,3.29,0,0,1-4.8-2.179,4.136,4.136,0,0,1-3.847.666V1.5h-6.01a3.459,3.459,0,0,0,.776,2.333,2.685,2.685,0,0,0,2.074.8,7.027,7.027,0,0,0,2.786-.589V5.22a7.032,7.032,0,0,1-1.325.433A7.438,7.438,0,0,1-7.055,5.785Z"
                transform="translate(177.525 51.764)"
              />
              <path
                d="M-7.724-19.083a4.294,4.294,0,0,0,.677-.052,4.544,4.544,0,0,0,.518-.107v1.011a2.451,2.451,0,0,1-.633.171,4.777,4.777,0,0,1-.752.067q-2.532,0-2.532-2.666v-5.19H-11.7v-.636l1.25-.55.557-1.863h.764v2.022h2.532v1.027H-9.125v5.135a1.767,1.767,0,0,0,.374,1.21A1.3,1.3,0,0,0-7.724-19.083Z"
                transform="translate(186.472 75.541)"
              />
              <path
                d="M22.471-159.5H7.488v-14.986H22.471Zm0-22.473H7.488A7.488,7.488,0,0,0,0-174.49V-159.5a7.488,7.488,0,0,0,7.488,7.486H22.471a7.487,7.487,0,0,0,7.487-7.486V-174.49A7.487,7.487,0,0,0,22.471-181.978Z"
                transform="translate(0 181.979)"
              />
              <path
                d="M-11.947,0a6.146,6.146,0,0,0-6.145,6.146,6.143,6.143,0,0,0,6.145,6.145A6.144,6.144,0,0,0-5.8,6.146,6.147,6.147,0,0,0-11.947,0Z"
                transform="translate(26.877 8.783)"
              />
              <path
                d="M-18.528,0l14.59,21.21V4.891L-3.98,1.01H-8.365V0h9.4V1.01H-2.589l-.042,3.881V30.15l-.675.211L-22.872,1.9V24.753l.041,4.005h4.511v1.012h-9.739V28.759h3.878l.042-4.005V4.891L-24.18,1.01h-3.878V0Z"
                transform="translate(69.444 0.032)"
              />
              <path
                d="M-51.326,0V9.529h-1.265a11.794,11.794,0,0,0-1.6-5.524c-1.518-2.572-3.585-2.7-5.608-2.783h-2.363l-.041,3.8v19.44l.041,4.3h4.556v1.012H-71.44V28.759h4.553l.043-4.3V5.017l-.043-3.8h-1.94c-2.445.041-4.3.041-6.071,2.867a10.414,10.414,0,0,0-1.6,5.44h-1.222V0Z"
                transform="translate(150.905 0.032)"
              />
              <path
                d="M-18.531,0l14.59,21.21V4.891L-3.982,1.01H-8.368V0h9.4V1.01H-2.592l-.043,3.881V30.15l-.675.211L-22.876,1.9V24.753l.043,4.005h4.514v1.012h-9.743V28.759h3.881l.039-4.005V4.891l-.039-3.881h-3.881V0Z"
                transform="translate(130.086 0.032)"
              />
              <path
                d="M-24.433,0V1.01H-28.61L-28.65,4.6V20.705c.04,4.175.127,8.138,7.97,8.138,7.927,0,8.433-3.458,8.559-7.211V4.6l-.041-3.586h-4.256V0H-7.1V1.01H-10.77L-10.811,4.6v15.35c-.045,5.525-.086,10.246-11.008,10.246a23.557,23.557,0,0,1-7.632-1.138c-3.753-1.6-3.837-4.891-3.88-8.94V4.6l-.043-3.586H-37V0Z"
                transform="translate(171.928 0.032)"
              />
            </g>
          </svg>
        );

      case 'ntnu':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="347.195"
            height="30.945"
            viewBox="0 0 347.195 30.945"
            fill={colour ? colour : '#231f20'}
          >
            <g transform="translate(0 0.001)">
              <path
                d="M-15.885,0H-14.4l-4.271,11.849h-1.362L-24.282,0h1.459L-20.1,7.667a21.429,21.429,0,0,1,.746,2.569,21.512,21.512,0,0,1,.762-2.618Z"
                transform="translate(230.404 8.485)"
              />
              <path
                d="M629.891-53.729h-1.345v-8.883h1.345Zm-1.459-11.29a.892.892,0,0,1,.227-.676.793.793,0,0,1,.567-.215.793.793,0,0,1,.56.219.88.88,0,0,1,.235.673.893.893,0,0,1-.235.677.788.788,0,0,1-.56.223.779.779,0,0,1-.567-.223A.91.91,0,0,1,628.432-65.019Z"
                transform="translate(-411.117 74.062)"
              />
              <path
                d="M-7.651-18.9a4.385,4.385,0,0,0,.689-.053,4.659,4.659,0,0,0,.527-.109v1.029a2.5,2.5,0,0,1-.645.174,4.864,4.864,0,0,1-.766.068q-2.578,0-2.578-2.715v-5.285H-11.7v-.648l1.273-.56.567-1.9h.778v2.058H-6.5v1.046H-9.078v5.228A1.8,1.8,0,0,0-8.7-19.334,1.324,1.324,0,0,0-7.651-18.9Z"
                transform="translate(232.148 38.29)"
              />
              <path
                d="M-7.344-2.131a2.2,2.2,0,0,0-1.706.7A3.207,3.207,0,0,0-9.8.495h4.644a2.96,2.96,0,0,0-.567-1.949,2,2,0,0,0-1.621-.677m.364,8.081a4.082,4.082,0,0,1-3.108-1.2,4.642,4.642,0,0,1-1.138-3.331,5.15,5.15,0,0,1,1.057-3.412,3.512,3.512,0,0,1,2.84-1.264,3.351,3.351,0,0,1,2.643,1.1,4.211,4.211,0,0,1,.972,2.9v.851H-9.832a3.514,3.514,0,0,0,.791,2.375,2.733,2.733,0,0,0,2.111.81,7.157,7.157,0,0,0,2.836-.6v1.2a7.192,7.192,0,0,1-1.349.441A7.578,7.578,0,0,1-6.98,5.949Z"
                transform="translate(238.219 14.547)"
              />
              <path
                d="M-11.5-17.111v-5.747a2.319,2.319,0,0,0-.495-1.62,2.014,2.014,0,0,0-1.548-.535,2.55,2.55,0,0,0-2.042.754,3.8,3.8,0,0,0-.649,2.488v4.66h-1.345v-8.883h1.094l.219,1.216h.065A2.746,2.746,0,0,1-15.041-25.8a3.767,3.767,0,0,1,1.661-.36,3.375,3.375,0,0,1,2.415.774,3.294,3.294,0,0,1,.811,2.476v5.8Z"
                transform="translate(254.378 37.445)"
              />
              <path
                d="M-11.913-12.527a2.226,2.226,0,0,1-.924,1.913,4.348,4.348,0,0,1-2.594.672,5.636,5.636,0,0,1-2.755-.559v-1.248a6.622,6.622,0,0,0,1.374.51,5.724,5.724,0,0,0,1.414.187,3.2,3.2,0,0,0,1.621-.337,1.113,1.113,0,0,0,.567-1.025,1.113,1.113,0,0,0-.45-.887,7.08,7.08,0,0,0-1.755-.871,9.491,9.491,0,0,1-1.763-.806,2.406,2.406,0,0,1-.778-.782,2.04,2.04,0,0,1-.255-1.045,1.991,1.991,0,0,1,.883-1.715,4.114,4.114,0,0,1,2.423-.628,7.084,7.084,0,0,1,2.8.584l-.478,1.094A6.43,6.43,0,0,0-15-18.021a2.788,2.788,0,0,0-1.443.3.927.927,0,0,0-.486.827,1.008,1.008,0,0,0,.183.608,1.8,1.8,0,0,0,.587.478,13.035,13.035,0,0,0,1.556.656,5.814,5.814,0,0,1,2.136,1.159A2.046,2.046,0,0,1-11.913-12.527Z"
                transform="translate(264.63 30.437)"
              />
              <path
                d="M-2.515-15.256a15.508,15.508,0,0,1,1.062-1.3l2.869-3.04h1.6l-3.6,3.785,3.85,5.1H1.635l-3.136-4.2-1.013.876v3.323H-3.844V-23.32h1.329v6.686q0,.446-.065,1.378Z"
                transform="translate(258.75 31.043)"
              />
              <path
                d="M-5.842-15.287a2.733,2.733,0,0,0,1.974-.689A2.55,2.55,0,0,0-3.151-17.9v-.8l-1.346.057a4.889,4.889,0,0,0-2.313.5,1.5,1.5,0,0,0-.71,1.374,1.383,1.383,0,0,0,.442,1.11,1.831,1.831,0,0,0,1.236.381m3.04.948L-3.07-15.6h-.065A3.747,3.747,0,0,1-4.46-14.472a4.048,4.048,0,0,1-1.649.3,2.966,2.966,0,0,1-2.071-.681,2.482,2.482,0,0,1-.75-1.937q0-2.69,4.3-2.82l1.508-.048v-.551a2.249,2.249,0,0,0-.45-1.544,1.849,1.849,0,0,0-1.439-.5,5.861,5.861,0,0,0-2.513.681l-.413-1.029a6.312,6.312,0,0,1,1.439-.56,6.229,6.229,0,0,1,1.568-.2,3.386,3.386,0,0,1,2.355.705A2.943,2.943,0,0,1-1.806-20.4v6.063Z"
                transform="translate(271.884 34.673)"
              />
              <path
                d="M-7.451-2.161a2.384,2.384,0,0,0-1.97.754,3.875,3.875,0,0,0-.624,2.4v.3A4.442,4.442,0,0,0-9.421,3.97a2.372,2.372,0,0,0,2,.806,2.083,2.083,0,0,0,1.8-.932,4.426,4.426,0,0,0,.653-2.569,4.259,4.259,0,0,0-.653-2.549,2.148,2.148,0,0,0-1.836-.887M-7.257,5.9a3.839,3.839,0,0,1-1.584-.32,2.977,2.977,0,0,1-1.2-.985h-.1a11.884,11.884,0,0,1,.1,1.475V9.728H-11.39V-3.142H-10.3l.186,1.216h.065A3.12,3.12,0,0,1-8.837-2.98,3.672,3.672,0,0,1-7.257-3.3,3.285,3.285,0,0,1-4.529-2.1a5.32,5.32,0,0,1,.96,3.388,5.286,5.286,0,0,1-.977,3.4A3.29,3.29,0,0,1-7.257,5.9Z"
                transform="translate(284.24 14.593)"
              />
              <path
                d="M-11.913-12.527a2.226,2.226,0,0,1-.924,1.913,4.348,4.348,0,0,1-2.594.672,5.636,5.636,0,0,1-2.755-.559v-1.248a6.622,6.622,0,0,0,1.374.51,5.724,5.724,0,0,0,1.414.187,3.2,3.2,0,0,0,1.621-.337,1.113,1.113,0,0,0,.567-1.025,1.113,1.113,0,0,0-.45-.887,7.08,7.08,0,0,0-1.755-.871,9.491,9.491,0,0,1-1.763-.806,2.406,2.406,0,0,1-.778-.782,2.04,2.04,0,0,1-.255-1.045,1.991,1.991,0,0,1,.883-1.715,4.114,4.114,0,0,1,2.423-.628,7.084,7.084,0,0,1,2.8.584l-.478,1.094A6.43,6.43,0,0,0-15-18.021a2.788,2.788,0,0,0-1.443.3.927.927,0,0,0-.486.827,1.008,1.008,0,0,0,.183.608,1.8,1.8,0,0,0,.587.478,13.035,13.035,0,0,0,1.556.656,5.814,5.814,0,0,1,2.136,1.159A2.046,2.046,0,0,1-11.913-12.527Z"
                transform="translate(300.664 30.437)"
              />
              <path
                d="M-21.42-17.111V-22.89a2.4,2.4,0,0,0-.454-1.593,1.757,1.757,0,0,0-1.41-.531,2.276,2.276,0,0,0-1.856.721,3.425,3.425,0,0,0-.6,2.221v4.96h-1.345V-22.89a2.4,2.4,0,0,0-.454-1.593,1.767,1.767,0,0,0-1.418-.531,2.195,2.195,0,0,0-1.852.758,4.093,4.093,0,0,0-.588,2.484v4.66h-1.345v-8.883h1.094l.219,1.216h.065a2.642,2.642,0,0,1,1.074-1.013,3.283,3.283,0,0,1,1.552-.364,2.639,2.639,0,0,1,2.723,1.508h.065a2.828,2.828,0,0,1,1.151-1.1,3.568,3.568,0,0,1,1.719-.405,3.007,3.007,0,0,1,2.257.774,3.464,3.464,0,0,1,.75,2.476v5.8Z"
                transform="translate(323.682 37.445)"
              />
              <path
                d="M-2.576,0V5.763a2.314,2.314,0,0,0,.495,1.621,2.013,2.013,0,0,0,1.548.535A2.527,2.527,0,0,0,1.5,7.157a3.845,3.845,0,0,0,.645-2.488V0H3.494V8.883H2.384L2.19,7.691H2.116A2.683,2.683,0,0,1,.97,8.7,3.859,3.859,0,0,1-.7,9.045a3.4,3.4,0,0,1-2.428-.77,3.276,3.276,0,0,1-.806-2.464V0Z"
                transform="translate(310.22 11.451)"
              />
              <path
                d="M-11.913-12.527a2.226,2.226,0,0,1-.924,1.913,4.348,4.348,0,0,1-2.594.672,5.636,5.636,0,0,1-2.755-.559v-1.248a6.622,6.622,0,0,0,1.374.51,5.724,5.724,0,0,0,1.414.187,3.2,3.2,0,0,0,1.621-.337,1.113,1.113,0,0,0,.567-1.025,1.113,1.113,0,0,0-.45-.887,7.08,7.08,0,0,0-1.755-.871,9.491,9.491,0,0,1-1.763-.806,2.406,2.406,0,0,1-.778-.782,2.04,2.04,0,0,1-.255-1.045,1.991,1.991,0,0,1,.883-1.715,4.114,4.114,0,0,1,2.423-.628,7.084,7.084,0,0,1,2.8.584l-.478,1.094A6.43,6.43,0,0,0-15-18.021a2.788,2.788,0,0,0-1.443.3.927.927,0,0,0-.486.827,1.008,1.008,0,0,0,.183.608,1.8,1.8,0,0,0,.587.478,13.035,13.035,0,0,0,1.556.656,5.814,5.814,0,0,1,2.136,1.159A2.046,2.046,0,0,1-11.913-12.527Z"
                transform="translate(334.21 30.437)"
              />
              <path
                d="M-7.344-2.131a2.2,2.2,0,0,0-1.706.7A3.207,3.207,0,0,0-9.8.495h4.644a2.96,2.96,0,0,0-.567-1.949,2,2,0,0,0-1.621-.677m.364,8.081a4.082,4.082,0,0,1-3.108-1.2,4.642,4.642,0,0,1-1.138-3.331,5.15,5.15,0,0,1,1.057-3.412,3.512,3.512,0,0,1,2.84-1.264,3.351,3.351,0,0,1,2.643,1.1,4.211,4.211,0,0,1,.972,2.9v.851H-9.832a3.514,3.514,0,0,0,.791,2.375,2.733,2.733,0,0,0,2.111.81,7.157,7.157,0,0,0,2.836-.6v1.2a7.192,7.192,0,0,1-1.349.441A7.578,7.578,0,0,1-6.98,5.949Z"
                transform="translate(335.217 14.547)"
              />
              <path
                d="M-7.344-2.131a2.2,2.2,0,0,0-1.706.7A3.207,3.207,0,0,0-9.8.495h4.644a2.96,2.96,0,0,0-.567-1.949,2,2,0,0,0-1.621-.677m.364,8.081a4.082,4.082,0,0,1-3.108-1.2,4.642,4.642,0,0,1-1.138-3.331,5.15,5.15,0,0,1,1.057-3.412,3.512,3.512,0,0,1,2.84-1.264,3.351,3.351,0,0,1,2.643,1.1,4.211,4.211,0,0,1,.972,2.9v.851H-9.832a3.514,3.514,0,0,0,.791,2.375,2.733,2.733,0,0,0,2.111.81,7.157,7.157,0,0,0,2.836-.6v1.2a7.192,7.192,0,0,1-1.349.441A7.578,7.578,0,0,1-6.98,5.949Z"
                transform="translate(344.53 14.547)"
              />
              <path
                d="M-7.651-18.9a4.385,4.385,0,0,0,.689-.053,4.659,4.659,0,0,0,.527-.109v1.029a2.5,2.5,0,0,1-.645.174,4.864,4.864,0,0,1-.766.068q-2.578,0-2.578-2.715v-5.285H-11.7v-.648l1.273-.56.567-1.9h.778v2.058H-6.5v1.046H-9.078v5.228A1.8,1.8,0,0,0-8.7-19.334,1.324,1.324,0,0,0-7.651-18.9Z"
                transform="translate(353.631 38.29)"
              />
              <path
                d="M22.879-66.605H7.624V-81.864H22.879Zm0-22.883H7.624A7.625,7.625,0,0,0,0-81.864v15.258a7.623,7.623,0,0,0,7.624,7.621H22.879A7.623,7.623,0,0,0,30.5-66.605V-81.864A7.624,7.624,0,0,0,22.879-89.488Z"
                transform="translate(0 89.487)"
              />
              <path
                d="M-11.836,0a6.258,6.258,0,0,0-6.256,6.258,6.255,6.255,0,0,0,6.256,6.256A6.255,6.255,0,0,0-5.579,6.258,6.259,6.259,0,0,0-11.836,0Z"
                transform="translate(27.036 8.941)"
              />
              <path
                d="M-18.355,0-3.5,21.6V4.98l-.043-3.952H-8.007V0H1.566V1.028H-2.127L-2.169,4.98V30.7l-.687.215L-22.778,1.931V25.2l.042,4.078h4.593v1.031h-9.916V29.281h3.948l.043-4.078V4.98l-.043-3.952h-3.948V0Z"
                transform="translate(70.196 0.031)"
              />
              <path
                d="M-50.845,0V9.7h-1.288a12.019,12.019,0,0,0-1.633-5.624c-1.545-2.618-3.65-2.748-5.71-2.834h-2.4l-.042,3.865V24.9l.042,4.381h4.638v1.031H-71.325V29.281h4.636l.044-4.381V5.108l-.044-3.865h-1.975c-2.49.043-4.379.043-6.182,2.919A10.6,10.6,0,0,0-76.478,9.7h-1.244V0Z"
                transform="translate(152.233 0.031)"
              />
              <path
                d="M-18.358,0-3.5,21.6V4.98l-.042-3.952H-8.011V0H1.564V1.028H-2.129L-2.173,4.98V30.7l-.687.215L-22.781,1.931V25.2l.044,4.078h4.6v1.031h-9.92V29.281h3.952l.04-4.078V4.98l-.04-3.952h-3.952V0Z"
                transform="translate(131.939 0.031)"
              />
              <path
                d="M-24.205,0V1.028h-4.252L-28.5,4.679v16.4c.041,4.251.129,8.286,8.115,8.286,8.07,0,8.586-3.521,8.714-7.342V4.679l-.041-3.651h-4.334V0H-6.56V1.028h-3.733l-.042,3.651V20.308c-.045,5.625-.088,10.432-11.208,10.432a23.978,23.978,0,0,1-7.77-1.158c-3.822-1.632-3.907-4.98-3.951-9.1V4.679l-.044-3.651H-37V0Z"
                transform="translate(174.378 0.031)"
              />
              <path
                fill={colour ? colour : '#1d1c25'}
                d="M-.654-57.386A.346.346,0,0,1-1-57.732V-87.374a.346.346,0,0,1,.346-.346.346.346,0,0,1,.346.346v29.642A.346.346,0,0,1-.654-57.386Z"
                transform="translate(187.223 87.756)"
              />
            </g>
          </svg>
        );

      default:
        break;
    }
  };

  return (
    <div className={className} alt="">
      {vector(type)}
    </div>
  );
};

export default Vector;
