import React from 'react';
import t, { _t } from '../../../utils/translate';
import classNames from 'classnames';
import styles from './FooterInfoBlock.module.scss';
import Vector from '../../Vector/Vector';

const FooterInfoBlock = ({ headline, content, textField, dark, override }) => {
  let inverted = false;

  if (override) inverted = true;

  if (content[0]._type === 'location') {
    return (
      <div
        className={classNames(
          inverted ? styles.inverted : undefined,
          styles.location,
          styles.infoBlockOuter,
          dark ? styles.dark : undefined
        )}
      >
        <div className={styles.textOuter}>
          <h3 className={classNames(styles.infoHead, 'body-text')}>
            {_t(headline)}
          </h3>
          {textField && (
            <p className={classNames(styles.textField, 'body-text-s')}>
              {_t(textField)}
            </p>
          )}
          {content[0].links.map((link, index) => {
            const { links, urlIcon } = link;
            return (
              <a
                className={styles.links}
                key={index}
                href={links.linkTo[0].href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg className={styles.icon}>
                  <image
                    xlinkHref={urlIcon.asset.url}
                    src={urlIcon.asset.url}
                  />
                </svg>
                {_t(links.text)}
              </a>
            );
          })}
        </div>
      </div>
    );
  }

  if (content[0]._type === 'hours') {
    const splitHours = _t(content[0].hours).split('\n');

    return (
      <div
        className={classNames(
          inverted ? styles.inverted : undefined,
          styles.infoBlockOuter,
          dark ? styles.dark : undefined
        )}
      >
        <div className={styles.textWrapper}>
          <h3 className={classNames(styles.infoHead, 'body-text')}>
            {_t(headline)}
          </h3>
          {textField.nb != null && (
            <p className={classNames(styles.textField, 'body-text-s')}>
              {_t(textField)}
            </p>
          )}
          {splitHours.map((hour, index) => (
            <p
              key={index}
              className={classNames(styles.hoursText, 'body-text-s')}
            >
              {hour}
            </p>
          ))}
        </div>
      </div>
    );
  }

  if (content[0]._type === 'ticketPrices') {
    return (
      <div
        className={classNames(
          inverted ? styles.inverted : undefined,
          styles.infoBlockOuter,
          dark ? styles.dark : undefined
        )}
      >
        <div className={styles.textWrapper}>
          <div className={styles.priceTextOuter}>
            <p className={classNames(styles.infoHead, 'body-text')}>
              {_t(headline)}
            </p>
          </div>
          <div className={styles.ticketPricesOuter}>
            {content[0].prices.map((price, index) => {
              return (
                <div className={styles.priceRowOuter} key={index}>
                  <div
                    className={classNames(styles.priceRowInner, 'body-text-s')}
                  >
                    <span>{_t(price.priceCategory)}</span>
                    <span>{price.price},-</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.priceLinkOuter}>
          <a
            className={classNames(styles.priceLink, 'body-text-s')}
            href={t('ticketUrl')}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Vector
              type={'external'}
              colour="#000"
              className={styles.externalIcon}
            />
            {t('ticket')}
          </a>
        </div>
      </div>
    );
  }
};

export default FooterInfoBlock;
