export default {
  shortcuts: 'Snarveier',
  fpTicket: 'Kjøp billetter',
  ticket: 'Kjøp billetter her',
  ticketUrl: 'https://nettbutikk.seesalmon.no/',
  ticketUrlTrondheim: 'https://nettbutikk.seesalmon.no/Detaljer/EVENT/35',
  eventHeadline: 'Arrangementer',
  loadMore: 'Last flere',
  frontpage: 'Forside',
  videoAlert: 'Beklager, din nettleser støtter ikke videoer.',
  learningHeadline: 'Praktiske aktiviteter',
  linkedLearningHeadline: 'Andre praktiske aktiviteter',
  menuShow: 'Meny',
  menuHide: 'Lukk',
  navAbout: 'Om oss',
  navVisit: 'Besøk',
  navExperiences: 'Opplevelser',
  prevSlide: 'Forrige bilde',
  nextSlide: 'Neste bilde',
  contactInfo: 'Kontaktinformasjon',
  ticketPrices: 'Billetter',
  backToFront: 'Tilbake til forsiden',
  fourOhFour: '404 feil',
  noPage: 'Beklager, siden du ser etter finnes ikke.',
  toTop: 'Scroll til toppen av siden',
  imageSelect: 'Velg bilde nummer',
  imageCarousel: 'Bildekarusell',
  skipToContent: 'Hopp til hovedinnholdet',
  readMore: 'Les mer her',
};
