import React from 'react';
import classNames from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import styles from './ContactInfo.module.scss';
import { _t } from '../../utils/translate';
import { serializers } from '../LinkAnnotation/LinkAnnotation';

export default function ContactInfo({ text, info, dark, aboutPage }) {
  const headline = { __html: _t(text) };
  return (
    <div
      className={classNames(
        'body-text-s',
        styles.contactInfo,
        aboutPage ? styles.aboutPage : null,
        dark ? styles.dark : null
      )}
    >
      <h3
        className={classNames(styles.contactHead, 'body-text')}
        dangerouslySetInnerHTML={headline}
      />

      {info.map((info, index) => {
        const { addressField, links, linkIcon } = info;

        return (
          <div className={styles.addressOuter} key={index}>
            <BlockContent blocks={_t(addressField)} serializers={serializers} />
            <a
              className={styles.addressLink}
              target="_blank"
              rel="noopener noreferrer"
              href={links.linkTo[0].href}
            >
              <svg className={styles.icon}>
                <image
                  xlinkHref={linkIcon.asset.url}
                  src={linkIcon.asset.url}
                />
              </svg>
              {_t(links.text)}
            </a>
          </div>
        );
      })}
    </div>
  );
}
