import React from 'react';
// import { Link } from 'gatsby';
import styles from './Logo.module.scss';

import LocaleLink from '../LocaleLink/LocaleLink';

export default function Logo({ fill }) {
  return (
    <div className={styles.logoOuter}>
      <LocaleLink className={styles.logo} to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="45.669"
          viewBox="0 0 280 45.669"
          fill={fill ? fill : '#fff'}
        >
          <g transform="translate(72.486)">
            <path
              d="M272.386,2.368,270.018,0,264.01,6.008l6.008,6.008,2.368-2.368A5.143,5.143,0,0,0,272.386,2.368Z"
              transform="translate(-247.183)"
            />
            <path
              d="M297.256,27.238l-2.368-2.368-6.008,6.008,6.008,6.008,2.368-2.368A5.146,5.146,0,0,0,297.256,27.238Z"
              transform="translate(-263.64 -16.457)"
            />
            <path
              d="M272.386,52.1l-2.368-2.368-6.008,6.011,6.008,6.008,2.368-2.368A5.147,5.147,0,0,0,272.386,52.1Z"
              transform="translate(-247.183 -32.907)"
            />
            <path
              d="M247.516,27.238l-2.368-2.368-6.008,6.008,6.008,6.008,2.368-2.368A5.143,5.143,0,0,0,247.516,27.238Z"
              transform="translate(-230.727 -16.457)"
            />
            <path
              d="M222.646,52.1l-2.368-2.368-6.008,6.011,6.008,6.008,2.368-2.368A5.147,5.147,0,0,0,222.646,52.1Z"
              transform="translate(-214.27 -32.907)"
            />
            <path
              d="M247.516,76.968,245.148,74.6l-6.008,6.008,6.008,6.008,2.368-2.368A5.143,5.143,0,0,0,247.516,76.968Z"
              transform="translate(-230.727 -49.364)"
            />
            <path
              d="M272.386,101.838l-2.368-2.368-6.008,6.008,6.008,6.011,2.368-2.368A5.147,5.147,0,0,0,272.386,101.838Z"
              transform="translate(-247.183 -65.82)"
            />
            <path
              d="M297.256,76.968,294.888,74.6l-6.008,6.008,6.008,6.008,2.368-2.368A5.146,5.146,0,0,0,297.256,76.968Z"
              transform="translate(-263.64 -49.364)"
            />
            <path
              d="M322.126,52.1l-2.368-2.368-6.008,6.011,6.008,6.008,2.368-2.368A5.153,5.153,0,0,0,322.126,52.1Z"
              transform="translate(-280.097 -32.907)"
            />
          </g>
          <g transform="translate(0 8.579)">
            <path
              d="M3.836,46.26c.775,3.43,3.1,5.267,7.226,5.267,4.408,0,6.164-2.08,6.164-4.777,0-2.859-1.387-4.449-7.064-5.795-6.691-1.634-9.019-3.921-9.019-7.919,0-4.246,3.1-7.676,9.432-7.676,6.776,0,9.553,3.917,9.959,7.635h-3.84c-.531-2.49-2.08-4.57-6.245-4.57-3.471,0-5.389,1.553-5.389,4.246,0,2.652,1.593,3.714,6.573,4.939,8.082,1.959,9.513,5.1,9.513,8.775,0,4.57-3.39,8.163-10.328,8.163C3.674,54.548.612,50.63,0,46.26Z"
              transform="translate(0 -25.36)"
            />
            <path
              d="M91.891,41.715H77.767v10.04H93.278l-.491,3.224H74.09V26.57H92.581v3.224H77.764v8.694H91.887v3.227Z"
              transform="translate(-49.026 -26.161)"
            />
            <path
              d="M159.1,41.715H144.977v10.04h15.511L160,54.98H141.3V26.57h18.491v3.224H144.974v8.694H159.1v3.227Z"
              transform="translate(-93.499 -26.161)"
            />
          </g>
          <g transform="translate(122.505 8.579)">
            <path
              d="M365.966,46.26c.775,3.43,3.1,5.267,7.226,5.267,4.408,0,6.164-2.08,6.164-4.777,0-2.859-1.387-4.449-7.064-5.795-6.695-1.634-9.022-3.917-9.022-7.919,0-4.246,3.1-7.676,9.432-7.676,6.776,0,9.553,3.917,9.959,7.635h-3.836c-.531-2.49-2.08-4.57-6.245-4.57-3.471,0-5.389,1.553-5.389,4.246,0,2.652,1.593,3.714,6.573,4.939,8.082,1.959,9.513,5.1,9.513,8.775,0,4.57-3.39,8.163-10.328,8.163-7.145,0-10.206-3.917-10.818-8.288Z"
              transform="translate(-362.13 -25.36)"
            />
            <path
              d="M434.467,46.441l-3.061,8.532H427.65L437.775,26.56h4.614l10.572,28.413h-4.043l-3.143-8.532Zm10.369-3.224c-2.733-7.469-4.2-11.431-4.817-13.593h-.041c-.694,2.409-2.368,7.226-4.53,13.593Z"
              transform="translate(-405.485 -26.154)"
            />
            <path
              d="M512,26.57h3.755V51.756h14.817l-.531,3.224H512Z"
              transform="translate(-461.3 -26.161)"
            />
            <path
              d="M598.639,42.9c0-5.308.081-10.656.2-13.633h-.162c-1.225,4.614-5.8,16.779-9.063,25.717h-3.39c-2.449-7.266-7.226-20.94-8.491-25.8h-.122c.2,3.305.328,9.594.328,14.492V54.986H574.39V26.57h5.592c3.224,8.816,7.185,20.206,8.163,23.8h.041c.694-2.737,5.348-15.186,8.694-23.8h5.43V54.983h-3.674V42.9Z"
              transform="translate(-502.584 -26.161)"
            />
            <path
              d="M695.939,39.812c0,7.838-4.367,14.777-13.308,14.777-8.369,0-13.021-6.451-13.021-14.614,0-8.122,4.736-14.614,13.349-14.614C690.96,25.36,695.939,31.361,695.939,39.812Zm-22.371.041c0,6.123,3.1,11.512,9.225,11.512,6.573,0,9.225-5.43,9.225-11.471,0-6.123-2.98-11.309-9.225-11.309C676.386,28.584,673.568,33.811,673.568,39.852Z"
              transform="translate(-565.592 -25.36)"
            />
            <path
              d="M760.11,54.98V26.57h4.979c4.083,6.654,12.94,20.778,14.533,23.839h.041c-.284-3.633-.244-8.122-.244-12.737V26.57h3.552V54.983h-4.7c-3.755-6.164-12.98-21.35-14.736-24.533h-.081c.2,3.224.2,8.041.2,13.187v11.35H760.11Z"
              transform="translate(-625.477 -26.161)"
            />
          </g>
        </svg>
      </LocaleLink>
    </div>
  );
}
