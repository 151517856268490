export default {
  shortcuts: 'Shortcuts',
  fpTicket: 'Buy tickets',
  ticket: 'Buy tickets here',
  ticketUrl: 'https://nettbutikk.seesalmon.no/',
  ticketUrlTrondheim: 'https://nettbutikk.seesalmon.no/Detaljer/EVENT/35',
  eventHeadline: 'Events',
  loadMore: 'Load more',
  frontpage: 'Frontpage',
  videoAlert: "Sorry, your browser doesn't support embedded videos.",
  learningHeadline: 'Learning resources',
  linkedLearningHeadline: 'Other learning resources',
  menuShow: 'Menu',
  menuHide: 'Close',
  navAbout: 'About',
  navVisit: 'Visit',
  navExperiences: 'Experiences',
  prevSlide: 'Previous image',
  nextSlide: 'Next image',
  contactInfo: 'Contact Information',
  ticketPrices: 'Tickets',
  backToFront: 'Back to the frontpage',
  fourOhFour: '404 error',
  noPage: 'Sorry, the page you were looking for doesn’t exist.',
  toTop: 'Scroll to the top of the page',
  imageSelect: 'Choose image number',
  imageCarousel: 'Image carousel',
  skipToContent: 'Skip to main content',
  readMore: 'Read more',
};
