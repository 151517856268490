import React from 'react';
import { useMatch } from '@reach/router';
import { Link } from 'gatsby';

const LocaleLink = ({ to, children, ...rest }) => {
  const match = useMatch('/en/*');

  return (
    <Link to={!!match ? `/en${to}` : to} {...rest}>
      {children}
    </Link>
  );
};

export default LocaleLink;
