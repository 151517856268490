import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import { _t } from '../../../utils/translate';
import styles from './MobileNav.module.scss';
import LangSwitch from '../../LangSwitch/LangSwitch';
import LocaleLink from '../../LocaleLink/LocaleLink';
import Nav from '../Nav';

export default function MobileNav({ show, location }) {
  const dark = true;

  return (
    <StaticQuery
      query={graphql`
        query {
          sanityFooter {
            ...FooterQuery
          }
        }
      `}
      render={({ sanityFooter: { footerInfo } }) => (
        <div
          className={classNames(
            styles.mobileNavOuter,
            show ? styles.show : null
          )}
        >
          <Nav vertical={true} />

          <div className={styles.info}>
            {footerInfo.map((info, index) => {
              const { textField, headline, content } = info;
              if (content[0]._type === 'location') {
                return (
                  <div
                    key={index}
                    className={classNames(
                      styles.location,
                      styles.infoOuter,
                      dark ? styles.dark : undefined
                    )}
                  >
                    <div className={styles.textOuter}>
                      <p className={classNames(styles.infoHead, 'body-text')}>
                        {_t(headline)}
                      </p>
                      {textField && (
                        <p
                          className={classNames(
                            styles.textField,
                            'body-text-s'
                          )}
                        >
                          {_t(textField)}
                        </p>
                      )}
                      {content[0].links.map((link, index) => {
                        const { links, urlIcon } = link;
                        return (
                          <a
                            className={styles.links}
                            key={index}
                            href={links.linkTo[0].href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg className={styles.icon}>
                              <image
                                xlinkHref={urlIcon.asset.url}
                                src={urlIcon.asset.url}
                              />
                            </svg>
                            {_t(links.text)}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                );
              } else if (content[0]._type === 'hours') {
                const splitHours = _t(content[0].hours).split('\n');

                return (
                  <div
                    key={index}
                    className={classNames(
                      styles.infoOuter,
                      dark ? styles.dark : undefined
                    )}
                  >
                    <div className={styles.textWrapper}>
                      <p className={classNames(styles.infoHead, 'body-text')}>
                        {_t(headline)}
                      </p>
                      {textField && (
                        <p
                          className={classNames(
                            styles.textField,
                            'body-text-s'
                          )}
                        >
                          {_t(textField)}
                        </p>
                      )}
                      {splitHours.map((hour, index) => (
                        <p
                          key={index}
                          className={classNames(
                            styles.hoursText,
                            'body-text-s'
                          )}
                        >
                          {hour}
                        </p>
                      ))}
                    </div>
                  </div>
                );
              } else if (content[0]._type === 'ticketPrices') {
                return (
                  <div
                    key={index}
                    className={classNames(
                      styles.infoOuter,
                      dark ? styles.dark : undefined
                    )}
                  >
                    <div className={styles.textWrapper}>
                      <div className={styles.priceTextOuter}>
                        <p className={classNames(styles.infoHead, 'body-text')}>
                          {_t(headline)}
                        </p>
                      </div>
                      <div className={styles.ticketPricesOuter}>
                        {content[0].prices.map((price, index) => {
                          return (
                            <div className={styles.priceRowOuter} key={index}>
                              <div
                                className={classNames(
                                  styles.priceRowInner,
                                  'body-text-s'
                                )}
                              >
                                <span>{_t(price.priceCategory)}</span>
                                <span>{price.price},-</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    {/* <div className={styles.priceLinkOuter}>
                      <a
                        className={classNames(styles.priceLink, 'body-text-s')}
                        href={t('ticketUrl')}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <Vector
                          type={'external'}
                          colour="#000"
                          className={styles.externalIcon}
                        />
                        {t('ticket')}
                      </a>
                    </div> */}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>

          <div className={styles.bottomLinks}>
            <LocaleLink className={styles.privacyLink} to="/personvern">
              Personvernerklæring
            </LocaleLink>
            <LangSwitch />
          </div>
        </div>
      )}
    />
  );
}
