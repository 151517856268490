import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import t from '../../utils/translate';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import styles from './Layout.module.scss';
import ogImage from '../../../static/og/og-img1200x630.jpg';
import CookieWarning from '../CookieWarning/CookieWarning';
import SkipToContent from '../SkipToContent/SkipToContent';

const Layout = ({ children, location, dark }) => {
  const [showCookieWarning, setShowCookieWarning] = useState(false);
  const transitions = useTransition(showCookieWarning, null, {
    config: { duration: 250 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    let seen = localStorage.getItem('seen');
    setShowCookieWarning(seen ? false : true);
  }, []);

  const closeCookieWarning = () => {
    setShowCookieWarning(false);
  };

  return (
    <div
      className={classNames(styles.mainOuter, dark ? styles.dark : undefined)}
    >
      <Helmet>
        <html lang={t.getLocale()} />
        <title>SeeSalmon</title>
        <meta property="title" content="SeeSalmon" />
        <meta property="og:title" content="SeeSalmon" />
        <meta
          property="description"
          content="Bli med inn bak kulissene til en av Norges største næringer"
        />
        <meta
          property="og:description"
          content="Bli med inn bak kulissene til en av Norges største næringer"
        />
        <meta nampropertye="og:image" content={ogImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <link rel="stylesheet" href="https://use.typekit.net/hox6hvc.css" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#003d85" />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <div className={styles.mainInner}>
        {transitions.map(
          ({ item, key, props }) =>
            item && (
              <animated.div key={key} style={props}>
                <CookieWarning closeCookieWarning={closeCookieWarning} />
              </animated.div>
            )
        )}
        <SkipToContent />
        <Header location={location} />
        <main id="main">{children}</main>
        <Footer location={location} dark={dark} />
      </div>
    </div>
  );
};

export default Layout;
