import React from 'react';
import t from '../../utils/translate';
import styles from './SkipToContent.module.scss';

const SkipToContent = () => {
  return (
    <div className={styles.outer}>
      <div className={styles.inner}>
        <a href="#main">{t('skipToContent')}</a>.
      </div>
    </div>
  );
};

export default SkipToContent;
