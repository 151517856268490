import React from 'react';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import { useMatch, useLocation } from '@reach/router';
import styles from './LangSwitch.module.scss';

const LangSwitch = ({ inverted }) => {
  const match = useMatch('/en/*');
  const { pathname } = useLocation();

  const switcher = lang => {
    if (lang === 'en') {
      navigate(`/en${pathname}`);
    }

    if (lang === 'nb') {
      navigate(`${pathname.replace('/en/', '/')}`);
    }
  };

  return (
    <div
      className={classNames(
        styles.langOuter,
        inverted ? styles.inverted : undefined
      )}
    >
      <button
        className={!match ? styles.active : ''}
        onClick={() => switcher('nb')}
        disabled={!match}
      >
        <span className={styles.text}>NO</span>
      </button>
      {' / '}
      <button
        className={!!match ? styles.active : ''}
        onClick={() => switcher('en')}
        disabled={!!match}
      >
        <span className={styles.text}>ENG</span>
      </button>
    </div>
  );
};

export default LangSwitch;
