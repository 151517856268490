/*
 * This file is part of the Bama frukttimen 2020 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

import LinkComponent from '../Link/Link';

// import styles from './LinkAnnotation.module.css';

/**
 * This is the LinkAnnotation component.
 *
 * @author Magnus Bergman <magnus@apt.no>
 *
 * @return {JSX}
 */
function LinkAnnotation(props) {
  const {
    children,
    mark: { linkTo },
  } = props;

  return <LinkComponent to={linkTo}>{children}</LinkComponent>;
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
LinkAnnotation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  mark: PropTypes.shape({
    linkTo: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
      })
    ),
  }),
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
LinkAnnotation.defaultProps = {
  children: null,
  mark: [],
};

export const serializers = {
  marks: { link: LinkAnnotation },
};

export const toPlainText = (blocks = []) => {
  return (
    blocks
      // loop through each block
      .map(block => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== 'block' || !block.children) {
          return '';
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map(child => child.text).join('');
      })
      // join the paragraphs leaving split by two linebreaks
      .join('\n\n')
  );
};

export default LinkAnnotation;
