import React from 'react';
import classNames from 'classnames';
import styles from './Nav.module.scss';
import t from '../../utils/translate';

import LocaleLink from '../LocaleLink/LocaleLink';

export default function Nav({ vertical }) {
  return (
    <nav className={classNames(styles.nav, vertical ? styles.vert : null)}>
      <ul>
        <li>
          <LocaleLink
            partiallyActive={true}
            activeStyle={{ opacity: 1 }}
            to="/besok"
          >
            {t('navVisit')}
          </LocaleLink>
        </li>
        <li>
          <LocaleLink
            partiallyActive={true}
            activeStyle={{ opacity: 1 }}
            to="/opplevelser"
          >
            {t('navExperiences')}
          </LocaleLink>
        </li>
        <li>
          <LocaleLink
            partiallyActive={true}
            activeStyle={{ opacity: 1 }}
            to="/om-oss"
          >
            {t('navAbout')}
          </LocaleLink>
        </li>
      </ul>
    </nav>
  );
}
